import { CSSProperties, ReactNode, useEffect, useMemo, useState } from 'react';
import { getBrowser } from '@utils/Device';

type AspectRatioImageProps = {
  className?: string;
  imageClassName?: string;
  ratio: number;
  alt?: string;
  src: string;
  objectFit?: CSSProperties['objectFit'];
  children?: ReactNode;
  onLoadingComplete?: () => void;
};

export const AspectRatioImage = ({
  className,
  imageClassName,
  ratio,
  alt,
  src,
  objectFit = 'contain',
  children,
  onLoadingComplete,
}: AspectRatioImageProps) => {
  const [isCompatible, setIsCompatible] = useState(true);

  useEffect(() => {
    const browser = getBrowser();
    setIsCompatible(
      (browser.name === 'Chrome' && browser.version >= 88) ||
        (browser.name === 'Edge' && browser.version >= 88) ||
        (browser.name === 'Firefox' && browser.version >= 89) ||
        (browser.name === 'Safari' && browser.version >= 15) ||
        (browser.name === 'Opera' && browser.version >= 74)
    );
  }, []);

  return (
    <div className={`${className}`} style={{ aspectRatio: `${ratio}` }}>
      <img
        className={imageClassName}
        alt={alt}
        src={src}
        style={{
          width: '100%',
          height: '100%',
          objectFit,
        }}
        onLoad={onLoadingComplete}
      />

      {children}
    </div>
  );
};
