import { memo } from 'react';
import Link from 'next/link';
import Instagram from '@assets/icons/instagram-gradient.svg';
import LinkedIn from '@assets/icons/linkedin.svg';
import TikTok from '@assets/icons/tiktok.svg';
import { INSTAGRAM_ACCOUNT, LINKEDIN_ACCOUNT, TIKTOK_ACCOUNT } from 'constant/social';

type PublicFooterProps = {
  className?: string;
  simple?: boolean;
  absolute?: boolean;
  loggedIn?: boolean;
};

export const PublicFooter = memo(function PublicFooter({
  className = 'bg-gray5',
  simple = false,
  absolute = true,
  loggedIn = false,
}: PublicFooterProps) {
  if (simple) {
    return (
      <footer
        className={className}
        style={{ bottom: 0, width: '100%', position: absolute ? 'absolute' : 'relative' }}
      >
        <div
          className="container flex flex-col sm:flex-row justify-center gap-2 mx-auto p-4"
          style={{ justifyContent: 'left' }}
        >
          <div className="font-semibold text-1.5sm text-gray-150">
            By Social Circles Co. - {new Date().getFullYear()}
          </div>
        </div>
      </footer>
    );
  }

  return (
    <footer
      className={`${className} flex flex-col md:flex-row justify-between gap-4 py-8 px-8 md:px-28 lg:px-16 text-black text-1.5sm`}
      style={{ width: '100%' }}
    >
      <div className="flex md:flex-row flex-col md:items-center gap-x-16 gap-y-4">
        <h1>by Social Circles Co. - {new Date().getFullYear()}</h1>

        <div className="flex md:flex-row flex-col gap-4 md:gap-8 justify-between">
          <Link href="/aboutus" passHref>
            About Us
          </Link>
          {!loggedIn && (
            <>
              <Link href="/waiting-list" passHref>
                Join Waiting List
              </Link>
              <Link href="/waiting-list" passHref>
                Check Waiting List
              </Link>
            </>
          )}
          <Link href="/privacy" passHref>
            Privacy Policy
          </Link>
          <Link href="/terms-of-use" passHref>
            Terms of Use
          </Link>
        </div>
      </div>
    </footer>
  );
});
