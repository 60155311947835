import { useEffect, useState } from 'react';

import styles from './CommonGroundSection.module.css';

import { AspectRatioImage } from '@components/elements';

type CommonGroundSectionProps = {
  className?: string;
};

const cdnPrefix = process.env.NEXT_PUBLIC_CDN_DOMAIN || '';

const titles = ['Common Grounds', 'Fun Activities together', 'Circle of 6 people!'];
const descriptions = [
  'Dancing, Video Games, K-Dramas?  Choose any Common Grounds that show who you are.',
  'Watch a movie with your circle online or schedule a field trip together!',
  'Get a daily hand-picked circle based on your University, Location, and Common Ground or just create one.',
];

export const CommonGroundSection = ({ className }: CommonGroundSectionProps) => {
  const [index, setIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => setIndex((index) => (index + 1) % 3), 5000);
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <>
      {/* <div className="bg-gradient-to-b from-gray-5 via-green-1 to-gray-5 w-full flex justify-center">
        <div className="container mx-auto flex flex-col my-48 md:flex-row items-center gap-0 md:gap-12 justify-between px-8">
          <AspectRatioImage
            className={`${styles.image} w-2/3 md:w-1/3`}
            alt="Company Logo"
            src={cdnPrefix + '/assets/images/landing/company-logo.png'}
            ratio={290 / 298}
          />
          <div className="flex flex-col items-start md:w-1/2 py-8">
            <h1 className="font-petrona font-bold leading-tight text-right text-secondary w-full text-xl lg:text-2xl xl:text-4xl ">
              The First Indonesian <br /> <span className="italic">Exclusive</span> Social Network
            </h1>
            <p className="w-full mt-3 font-normal font-gtmaru text-right text-[1.025rem] lg:text-[1.35rem] xl:text-[1.75rem]">
              Socles is an <span className="font-black">exclusive, invite-only app.</span> <br />
              Only selected individuals can gain <br />
              access through waiting list.
            </p>
          </div>
        </div>
      </div> */}

      <div className="bg-gradient-to-b from-gray-5 via-green-2 to-gray-5 w-full flex justify-center">
        <div className="container mx-auto flex flex-col my-48 md:flex-row items-center gap-0 md:gap-12 justify-between px-8">
          <div className="flex flex-col items-start md:w-1/2 border-2 border-primary bg-green-1 rounded-3xl py-8 relative">
            <h1 className="relative font-petrona text-lg lg:text-xl font-bold leading-tight text-center w-full ">
              <span className="relative">
                <>Foster connections both in-app and in-person.</>
                <div className={`${styles.decor01}`}>
                  <div className="transform scale-75 lg:scale-100">
                    <img alt="" src={cdnPrefix + '/assets/images/landing/02/decor-01.svg'} />
                  </div>
                </div>
              </span>{' '}
              Play social games, Swipe on circles,
              <span className="relative">
                <> or even hangout. </>
                <div className={`hidden sm:block ${styles.decor02}`}>
                  <div className="transform scale-75 lg:scale-100">
                    <img alt="" src={cdnPrefix + '/assets/images/landing/02/decor-02.svg'} />
                  </div>
                </div>
              </span>
              <div className={`sm:hidden ${styles.decor02} ${styles.sm}`}>
                <div className="transform scale-75 lg:scale-100">
                  <img alt="" src={cdnPrefix + '/assets/images/landing/02/decor-02.svg'} />
                </div>
              </div>
            </h1>
          </div>
          <AspectRatioImage
            className={`${styles.image} w-2/3 md:w-1/3`}
            alt="Fasten your seatbelt"
            src={cdnPrefix + '/assets/images/landing/landing-feature-5.png'}
            ratio={290 / 298}
          />
        </div>
      </div>

      <div className="w-full container relative">
        <div
          className={`${styles.dots01} rounded-full bg-primary absolute w-2 h-2 top-24 left-9 sm:w-2 sm:h-2 sm:top-64 sm:left-14 md:w-3 md:h-3 md:top-52 md:left-16 lg:w-4 lg:h-4 lg:top-36 lg:left-28 xl:w-4 xl:h-4 xl:top-40 xl:left-36`}
        ></div>
        <div
          className={`${styles.dots04} rounded-full bg-secondary absolute w-1 h-1 top-64 left-1 sm:w-1 sm:h-1 sm:bottom-80 sm:left-1 md:w-2 md:h-2 md:bottom-50 md:left-0 lg:w-2 lg:h-2 lg:bottom-22 lg:left-0 xl:w-2 xl:h-2 xl:bottom-40 xl:left-0`}
        ></div>
        <div
          className={`${styles.dots03} rounded-full bg-secondary absolute w-2 h-2 bottom-40 left-14 sm:w-3 sm:h-3 sm:bottom-60 sm:left-16 md:w-4 md:h-4 md:bottom-44 md:left-20 lg:w-5 lg:h-5 lg:bottom-24 lg:left-36 xl:w-7 xl:h-7 xl:bottom-32 xl:left-44`}
        ></div>
        <div
          className={`${styles.dots04} rounded-full bg-primary absolute w-2 h-2 top-48 right-6 sm:w-3 sm:h-3 sm:top-64 sm:right-12 md:w-4 md:h-4 md:top-62 md:right-14 lg:w-4 lg:h-4 lg:top-48 lg:right-16 xl:w-5 xl:h-5 xl:top-40 xl:right-36`}
        ></div>
        <div
          className={`${styles.dots01} rounded-full bg-secondary absolute w-1 h-1 bottom-64 right-20 sm:w-2 sm:h-2 sm:top-80 sm:right-28 md:w-3 md:h-3 md:top-72 md:right-36 lg:w-3 lg:h-3 lg:top-72 lg:right-48 xl:w-3 xl:h-3 xl:top-56 xl:right-72`}
        ></div>
        <div
          className={`${styles.dots02} rounded-full bg-secondary absolute w-2 h-2 bottom-60 right-1 sm:w-3 sm:h-3 sm:bottom-80 md:w-4 md:h-4 md:bottom-88 md:right-0 lg:w-5 lg:h-5 lg:bottom-30 lg:right-0 xl:w-6 xl:h-6 xl:bottom-56 xl:-right-3`}
        ></div>
        <div
          className={`${styles.dots03} rounded-full bg-primary absolute w-1 h-1 bottom-52 right-10 sm:w-2 sm:h-2 sm:bottom-64 sm:right-14 md:w-3 md:h-3 md:bottom-60 md:right-16 lg:w-3 lg:h-3 lg:bottom-48 lg:right-20 xl:w-4 xl:h-4 xl:bottom-40 xl:right-32`}
        ></div>
        <AspectRatioImage
          className={`hidden w-full max-h-[778px] pb-8 sm:block`}
          alt="Fasten your seatbelt"
          src={cdnPrefix + '/assets/images/landing/circle.png'}
          ratio={239 / 298}
        />
        <AspectRatioImage
          className={`w-full max-h-[778px] pb-8 px-5 sm:hidden`}
          alt="Fasten your seatbelt"
          src={cdnPrefix + '/assets/images/landing/circle.png'}
          ratio={239 / 298}
        />
      </div>
    </>
  );
};
