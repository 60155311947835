import { NextPage } from 'next';

import Head from 'next/head';
import Link from 'next/link';
import { LandingPage } from '@components/modules';
import { PublicFooter } from '@components/elements';
import { AuthAction, withAuthUser, withAuthUserTokenSSR } from 'next-firebase-auth';

const Landing: NextPage = () => {
  return (
    <>
      <Head>
        <title>Socles · Exclusive Social Network</title>
        <meta
          name="description"
          content="Join Socles and be a part of the exclusive community and get new circles full of distinguished individuals based on your shared interests."
        />
      </Head>

      <LandingPage />
      <PublicFooter />
    </>
  );
};

export default withAuthUser({
  whenAuthed: AuthAction.REDIRECT_TO_APP,
  whenUnauthedBeforeInit: AuthAction.RENDER,
  whenUnauthedAfterInit: AuthAction.RENDER,
})(Landing);
