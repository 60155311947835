import { useEffect, useState } from 'react';
import styles from './HeroSection.module.css';
import TextTransition, { presets } from 'react-text-transition';

import { AspectRatioImage } from '@components/elements';
import Link from 'next/link';

type HeroSectionProps = {
  className?: string;
  onImageLoad?: () => void;
};

const cdnPrefix = process.env.NEXT_PUBLIC_CDN_DOMAIN || '';

export const HeroSection = ({ className, onImageLoad = () => {} }: HeroSectionProps) => {
  const [textIndex, setTextIndex] = useState(0);

  useEffect(() => {
    const id = setInterval(() => {
      setTextIndex((i) => (i + 1) % 4);
    }, 3000);

    return () => clearInterval(id);
  }, []);

  const texts = [
    'Foster friendships,',
    'Unveil Stories,',
    'Connect closer,',
    'Always (Re)Connected',
  ];

  return (
    <div
      className={`${className} ${styles.root} flex flex-col md:flex-row justify-between items-start gap-y-12 gap-x-6 lg:gap-x-16`}
    >
      <div className="flex flex-col self-center items-center w-full align">
        <h3 className={`${styles.textTitle} relative font-semibold leading-tight text-center`}>
          <div className={`${styles.decor01} top-0 left-0 xl:-top-1/3 xl:-left-1/10 `}>
            <img alt="" src={cdnPrefix + '/assets/images/landing/decor-03.svg'} />
          </div>
          <div className={`${styles.decor02} top-0 right-0 lg:-top-1/3 lg:-right-2/4 `}>
            <img alt="" src={cdnPrefix + '/assets/images/landing/decor-01.svg'} />
          </div>
          <span className="relative font-petrona font-xl font-normal tracking-tight">
            <div
              className={`${styles.decor03} invisible lg:visible lg:bottom-1/5 lg:-right-1/10 lg:w-60`}
            >
              <img alt="" src={cdnPrefix + '/assets/images/landing/landing-tag.png'} />
            </div>
            <br />
            <span className="relative text-center flex justify-center items-center -top-10 opacity-0">
              unveil unlimited stories
            </span>
            <div className={`${styles.decor02} right-0`}>
              <img alt="" src={cdnPrefix + '/assets/images/landing/decor-02.svg'} />
            </div>
          </span>
        </h3>
        <div className="flex w-full items-center flex-col text-[40px] leading-[1.2] md:text-[55px]">
          {texts.map((text, index) => {
            const nextTextIndex = (textIndex + 1) % texts.length;
            const prevTextIndex = textIndex === 0 ? texts.length - 1 : textIndex - 1;
            let className =
              'absolute text-center flex w-full flex-col transition-[transform,opacity] duration-500';
            if (index === textIndex) {
              className += ' opacity-100 translate-y-[calc(-50%-1em)] delay-[500ms]';
            } else if (nextTextIndex === index) {
              className += ' opacity-0 translate-y-[calc(-50%-1em-16px)]';
            } else if (prevTextIndex === index) {
              className += ' opacity-0 translate-y-[calc(-50%-1em+16px)]';
            } else {
              className += ' opacity-0';
            }
            return (
              <div key={text} className={className}>
                <div className="relative font-petrona font-semibold">{text}</div>
              </div>
            );
          })}
        </div>
        <h4
          style={{ marginTop: 32, fontSize: 20 }}
          className="text-center md:w-2/3 px-4 md:px-0 font-gtmaru font-normal"
        >
          <span>To the circle you belong...</span>
        </h4>
        <div className="relative text-center" style={{ width: '-webkit-fill-available' }}>
          <Link href="/waiting-list" passHref>
            <button className="mt-4 px-6 py-3 font-semibold text-white text-1.5md bg-secondary rounded-full tracking-wide">
              Join Waiting List
            </button>
          </Link>
          <div className={`${styles.decor01} bottom-0 -left-1/2 `}>
            <img alt="" src={cdnPrefix + '/assets/images/landing/decor-04.svg'} />
          </div>
          <div className={`${styles.decor02} bottom-0 -right-1/3 `}>
            <img alt="" src={cdnPrefix + '/assets/images/landing/decor-05.svg'} />
          </div>
        </div>
      </div>

      {/* <div className="grid grid-cols-2 gap-4 2xl:gap-6 md:w-1/2 lg:w-1/2 max-w-xl">
        <AspectRatioImage
          className={styles.person01}
          imageClassName="rounded-40 xl:rounded-60"
          onLoadingComplete={() => onPersonImageLoad(1)}
          src={cdnPrefix + '/assets/images/landing/person-1.png'}
          ratio={237 / 163}
        >
          <div className={`${styles.decorPerson01} transform scale-75 lg:scale-100`}>
            <img alt="" src={cdnPrefix + '/assets/images/landing/decor-person-01.svg'} />
          </div>
        </AspectRatioImage>

        <AspectRatioImage
          className={styles.person02}
          imageClassName="rounded-40 xl:rounded-60"
          onLoadingComplete={() => onPersonImageLoad(2)}
          src={cdnPrefix + '/assets/images/landing/person-2.png'}
          ratio={237 / 163}
        >
          <div className="absolute inset-0 z-10 border-2 lg:border-3 border-primary rounded-40 xl:rounded-60" />

          <div
            className={`${styles.decorPerson02} transform -translate-y-1/2 scale-75 lg:scale-100`}
          >
            <img alt="" src={cdnPrefix + '/assets/images/landing/decor-person-02.svg'} />
          </div>
        </AspectRatioImage>

        <AspectRatioImage
          className={styles.person03}
          imageClassName="rounded-40 xl:rounded-60"
          onLoadingComplete={() => onPersonImageLoad(3)}
          src={cdnPrefix + '/assets/images/landing/person-3.png'}
          ratio={237 / 163}
        >
          <div className="absolute inset-0 z-10 border-2 lg:border-3 border-primary rounded-40 xl:rounded-60" />
          <div className={`${styles.decorPerson05} transform scale-75 lg:scale-100`}>
            <img alt="" src={cdnPrefix + '/assets/images/landing/decor-person-05.svg'} />
          </div>
        </AspectRatioImage>

        <AspectRatioImage
          className={styles.person04}
          imageClassName="rounded-40 xl:rounded-60"
          onLoadingComplete={() => onPersonImageLoad(4)}
          src={cdnPrefix + '/assets/images/landing/person-4.png'}
          ratio={237 / 163}
        >
          <div
            className={`${styles.decorPerson04} transform translate-x-1/4 translate-y-1/4 scale-75 lg:scale-100`}
          >
            <img alt="" src={cdnPrefix + '/assets/images/landing/decor-person-04.svg'} />
          </div>
        </AspectRatioImage>
      </div> */}
    </div>
  );
};
