import Socles from '@assets/icons/socles.svg';

export const SoclesLogoFlex = ({ width, height }: { width?: number; height?: number }) => {
  return (
    <div className="flex items-center space-x-1">
      <Socles width={width} height={height} />
      <p className="font-gtmaru text-black md:text-xl text-lg font-medium">Socles</p>
    </div>
  );
};
